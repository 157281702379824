import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useLayoutEffect, useState } from 'react'

import logo from '../images/logo.png'
import text from './text.json'

type Contents = typeof text['it']
const validLanguageCodes = Object.keys(text)
export default memo(function Error404() {
  const [contents, setContents] = useState<Contents>(text.en)

  useLayoutEffect(() => {
    const language =
      (window.navigator as any).userLanguage || window.navigator.language
    const languageCode = language.substring(0, 2).toLowerCase()

    if (validLanguageCodes.includes(languageCode)) {
      setContents(text[languageCode as 'it'])
    }
  }, [])

  return (
    <Container>
      <Wrapper>
        <Logo src={logo} />
        <Title>{contents.title}</Title>
        <Text>{contents.text}</Text>
        <CTA
          label={useVocabularyData('back-to-home', contents.code)}
          URL={`/${contents.prefix}`}
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  font-size: 3rem;
  line-height: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    width: 90%;
  }
`

const Logo = styled.img`
  max-height: 7.5rem;
  margin-bottom: 3rem;
`

const Title = styled.h1`
  @media (max-width: 1023px) {
    font-size: 2.5rem;
  }
  @media (max-width: 571px) {
    font-size: 2.25rem;
  }
`

const Text = styled.div`
  @media (max-width: 1023px) {
    font-size: 2rem;
  }
  @media (max-width: 571px) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

const CTA = styled(Button)`
  margin: 3.75rem auto 0;

  @media (max-width: 1199px) {
    margin-top: 2.75rem;
  }
`
